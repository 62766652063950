import React from 'react';
import { Vars } from 'utils/vars';
import { Grid, GridColumnStyle } from 'utils/grid';

import P from 'components/P';
import H1 from 'components/H1';
import H2 from 'components/H2';
import Button from 'components/Button';

const sectionStyles = {
	marginTop: Vars.gutter,
	color: Vars.colorMetal,
	background: `linear-gradient(135deg, ${Vars.colorGrape} 0%, ${Vars.colorGrapeLight} 100%)`,
	clipPath: 'polygon(100% 0, 100% 96%, 0 100%, 0 4%)',
	':first-child': {
		marginTop: 0
	}
};

const sectionContainerStyles = {
	...Grid,
	...GridColumnStyle('x12', true),
	paddingTop: Vars.gap,
	paddingBottom: Vars.gap,
	alignItems: 'center',
	justifyContent: 'center',
	'@media (min-width: 992px)': {
		height: '370px'
	}
};

const sectionCopyStyles = {
	textAlign: 'center',
	...GridColumnStyle('x12')
};

const ResumePanel = () => (
	<section css={sectionStyles}>
		<div css={sectionContainerStyles}>
			<div css={sectionCopyStyles}>
				<H1>Let’s work together</H1>
				<H2>
					Get in touch at <b>me@tomas.tech</b> for new project enquiries.
				</H2>
				<P>
					Interested in hiring me full-time? Grab a{' '}
					<Button isLink isLight to="/media/tomas-jurkevicius-resume.pdf" download="tomas-jurkevicius-resume.pdf">
						copy of my résumé
					</Button>
					.
				</P>
			</div>
		</div>
	</section>
);

export default ResumePanel;
