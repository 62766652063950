import React from 'react';
import { Vars } from 'utils/vars';

const h3Styles = {
	marginTop: 0,
	marginBottom: `calc(${Vars.gutter} / 3)`,
	fontSize: '24px',
	fontFamily: Vars.fontFamilyHead,
	fontWeight: Vars.fontWeightHead,
	lineHeight: 1.2,
	':last-child': {
		marginBottom: 0
	}
};

const H3 = ({ children }) => <h3 css={h3Styles}>{children}</h3>;

export default H3;
