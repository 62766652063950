import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Vars } from 'utils/vars';
import { GridColumnStyle } from 'utils/grid';

import P from 'components/P';
import H1 from 'components/H1';
import H2 from 'components/H2';
import H3 from 'components/H3';
import Image from 'components/Image';
import Article from 'components/Article';
import Section from 'components/Section';

const articleStyles = {
	padding: Vars.gutter,
	textAlign: 'center',
	':nth-child(even)': {
		borderTopLeftRadius: Vars.gutter,
		borderBottomLeftRadius: Vars.gutter,
		background: `linear-gradient(90deg, ${Vars.colorMetal} 0%, rgba(255, 255, 255, 0) 100%)`
	},
	':nth-child(odd)': {
		borderTopRightRadius: Vars.gutter,
		borderBottomRightRadius: Vars.gutter,
		background: `linear-gradient(-90deg, ${Vars.colorMetal} 0%, rgba(255, 255, 255, 0) 100%)`
	},
	'@media (min-width: 768px)': {
		padding: 0,
		textAlign: 'left',
		':nth-child(odd)': {
			justifyContent: 'flex-end',
			'& :first-child': {
				order: 1
			}
		}
	}
};

const imageBlockStyles = {
	...GridColumnStyle('x12'),
	marginBottom: Vars.gutter,
	lineHeight: 0,
	'@media (min-width: 768px)': {
		...GridColumnStyle('x4'),
		marginBottom: 0
	}
};

const imageStyles = {
	maxWidth: '120px',
	'@media (min-width: 768px)': {
		maxWidth: '240px'
	}
};

const copyBlockStyles = {
	...GridColumnStyle('x12'),
	'@media (min-width: 768px)': GridColumnStyle('x7')
};

const sectionStyles = {
	textAlign: 'center'
};

const sectionTextStyles = {
	...GridColumnStyle('x12'),
	'@media (min-width: 576px)': GridColumnStyle('x8'),
	'@media (min-width: 768px)': GridColumnStyle('x6'),
	paddingBottom: Vars.gutter
};

const Service = ({ src, title, description }) => (
	<Article css={articleStyles}>
		<div css={imageBlockStyles}>
			<Image styles={imageStyles} src={src} alt={title} />
		</div>
		<div css={copyBlockStyles}>
			<H3>{title}</H3>
			<P>{description}</P>
		</div>
	</Article>
);

const ServiceList = () => (
	<StaticQuery
		query={graphql`
			query {
				site {
					siteMetadata {
						services {
							id
							src
							title
							description
						}
					}
				}
			}
		`}
		render={(data) => (
			<Section css={sectionStyles} inCSS={{ justifyContent: 'center' }}>
				<div css={sectionTextStyles}>
					<H1>Services</H1>
					<H2>I provide a helping hand to transform your business ideas into solid products that last.</H2>
				</div>
				{data.site.siteMetadata.services.map((item) => (
					<Service key={item.id} {...item} />
				))}
			</Section>
		)}
	/>
);

export default ServiceList;
