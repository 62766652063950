import React from 'react';
import { Vars } from 'utils/vars';
import { Grid, GridColumnStyle } from 'utils/grid';

const sectionStyles = {
	marginTop: Vars.gutter,
	':first-child': {
		marginTop: 0
	}
};

const sectionContainerStyles = {
	...Grid,
	...GridColumnStyle('x12', true),
	paddingTop: Vars.gutter,
	paddingBottom: Vars.gutter,
	alignItems: 'center'
};

const Section = ({ children, inCSS, ...other }) => (
	<section css={sectionStyles} {...other}>
		<div css={[inCSS, sectionContainerStyles]}>{children}</div>
	</section>
);

export default Section;
