import React from 'react';
import { graphql } from 'gatsby';

import H1 from 'components/H1';
import H2 from 'components/H2';
import Hero from 'components/Hero';
import Layout from 'components/Layout';

import ServiceList from 'containers/ServiceList';
import ResumePanel from 'containers/ResumePanel';

import HeroIllustration from 'components/SVG/HomePageIllustration';

const IndexPage = ({ data, location }) => (
	<Layout
		title="Tomas Jurkevičius - Front End Software Engineer Based In The French Riviera"
		description="I build modern web applications and marketing websites for ambitious companies"
		canonical={data.site.siteMetadata.siteUrl}
		location={location}>
		<Hero illustration={<HeroIllustration />}>
			<H1>Hello there!</H1>
			<H2>
				I’m Tomas Jurkevičius, a Software Engineer living in the French Riviera. I build modern web applications
				and marketing websites for ambitious companies.
			</H2>
		</Hero>
		<ServiceList />
		<ResumePanel />
	</Layout>
);

export const query = graphql`
	query IndexPageQuery {
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;

export default IndexPage;
