import React from 'react';
import { Vars } from 'utils/vars';
import { Grid, GridColumnStyle } from 'utils/grid';

const articleStyles = [
	Grid,
	GridColumnStyle('x12', true),
	{
		marginLeft: `calc(${Vars.gutter} / 2)`,
		marginRight: `calc(${Vars.gutter} / 2)`,
		alignItems: 'center',
		justifyContent: 'flex-start',
		'@media (min-width: 768px)': {
			height: '370px'
		}
	}
];

const Article = ({ children, ...other }) => (
	<article css={articleStyles} {...other}>
		{children}
	</article>
);

export default Article;
