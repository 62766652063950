import React from 'react';
import { keyframes } from 'glamor';

const loadIn = keyframes('loadAnimation', {
	'0%': { opacity: 0 },
	'100%': { opacity: 1 }
});

const Image = ({ styles, ...attrs }) => (
	<img
		css={[
			styles,
			{
				opacity: 0,
				transformOrigin: 'center',
				animation: `${loadIn} 0.3s forwards`
			}
		]}
		{...attrs}
	/>
);

export default Image;
