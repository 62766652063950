import React from 'react';
import anime from 'animejs';

const styles = {
	position: 'absolute',
	zIndex: 1,
	top: '50%',
	right: '30px',
	width: '300px',
	height: 'auto',
	transform: 'translateY(-50%)',
	pointerEvents: 'none',
	userSelect: 'none',
	display: 'none',
	'@media (min-width: 768px)': {
		display: 'block'
	},
	'@media (min-width: 992px)': {
		width: '420px'
	},
	'@media (min-width: 1200px)': {
		width: '600px'
	}
};

class HomePageIllustration extends React.Component {
	constructor(props) {
		super(props);

		this.blob = React.createRef();

		this.cardIn1Sq1 = React.createRef();
		this.cardIn1Sq2 = React.createRef();
		this.cardIn1Txt = React.createRef();
		this.cardIn1Base1 = React.createRef();
		this.cardIn1Base2 = React.createRef();

		this.cardIn2Sq1 = React.createRef();
		this.cardIn2Sq2 = React.createRef();
		this.cardIn2Txt = React.createRef();
		this.cardIn2Base1 = React.createRef();
		this.cardIn2Base2 = React.createRef();

		this.cardIn3Sq1 = React.createRef();
		this.cardIn3Sq2 = React.createRef();
		this.cardIn3Dot = React.createRef();
		this.cardIn3Txt = React.createRef();
		this.cardIn3Base1 = React.createRef();

		this.cardIn4Sq1 = React.createRef();
		this.cardIn4Sq2 = React.createRef();
		this.cardIn4Txt = React.createRef();
		this.cardIn4Base1 = React.createRef();
		this.cardIn4Base2 = React.createRef();

		this.cardOut1 = React.createRef();
		this.cardOut2 = React.createRef();
		this.cardOut3 = React.createRef();
		this.cardOut4 = React.createRef();

		this.cardDash1 = React.createRef();
		this.cardDash2 = React.createRef();

		this.cardPlain = React.createRef();

		this.frameFront = React.createRef();
		this.frameBack = React.createRef();

		this.personGround = React.createRef();

		this.searchBarBack = React.createRef();

		this.animationTimeLine = null;
	}

	componentDidMount() {
		this.animate();
	}

	componentWillUnmount() {
		if (this.animationTimeLine && !this.animationTimeLine.completed) {
			this.animationTimeLine.pause();
		}

		this.animationTimeLine = null;
	}

	animate() {
		this.animationTimeLine = anime.timeline({ autoplay: false });

		this.animationTimeLine
			.add({
				targets: this.blob.current,
				duration: 600,
				opacity: [0, 0.6],
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardOut1.current,
				translateX: [100, 0],
				duration: 500,
				opacity: [0, 1],
				offset: '-=600',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardOut2.current,
				translateX: [-100, 0],
				duration: 400,
				offset: '-=400',
				opacity: [0, 1],
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardOut3.current,
				translateX: [-100, 0],
				duration: 300,
				opacity: [0, 1],
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardOut4.current,
				translateX: [100, 0],
				duration: 500,
				offset: '-=400',
				opacity: [0, 1],
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.searchBarBack.current,
				duration: 800,
				offset: '-=800',
				opacity: [0, 1],
				easing: 'easeInOutSine'
			})
			.add({
				targets: this.frameFront.current,
				translateX: [5, 0],
				duration: 200,
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.frameBack.current,
				translateX: [10, 0],
				duration: 300,
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardPlain.current,
				duration: 800,
				offset: '-=800',
				opacity: [0, 1],
				easing: 'easeInOutSine'
			})
			.add({
				targets: this.personGround.current,
				duration: 400,
				offset: '-=800',
				opacity: [0.5, 1],
				easing: 'easeInOutSine'
			})
			.add({
				targets: this.cardIn1Base1.current,
				translateX: [-5, 0],
				duration: 300,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn1Base2.current,
				translateX: [-8, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn1Txt.current,
				translateX: [-10, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn1Sq1.current,
				translateX: [-15, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn1Sq2.current,
				translateX: [-20, 0],
				duration: 500,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn2Base1.current,
				translateX: [-5, 0],
				duration: 300,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn2Base2.current,
				translateX: [-8, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn2Txt.current,
				translateX: [-10, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn2Sq1.current,
				translateX: [-15, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn2Sq2.current,
				translateX: [-20, 0],
				duration: 500,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn3Base1.current,
				translateX: [-5, 0],
				duration: 300,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn3Txt.current,
				translateX: [-15, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn3Dot.current,
				translateX: [-10, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn3Sq1.current,
				translateX: [-15, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn3Sq2.current,
				translateX: [-30, 0],
				duration: 500,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn4Base1.current,
				translateX: [-5, 0],
				duration: 300,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn4Base2.current,
				translateX: [-8, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn4Txt.current,
				translateX: [-10, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn4Sq1.current,
				translateX: [-15, 0],
				duration: 400,
				offset: '-=400',
				easing: 'easeInOutBack'
			})
			.add({
				targets: this.cardIn4Sq2.current,
				translateX: [-20, 0],
				duration: 500,
				offset: '-=400',
				easing: 'easeInOutBack'
			});

		this.animationTimeLine.play();
	}

	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" css={styles}>
				<path
					ref={this.blob}
					d="M185.27,305.46c51.22,24.18,68.85,72.91,107.63,110.16C327.68,449,378.12,458,424.31,448.06,515.44,428.4,561.58,334.42,560,247.17,558.35,160.37,512.6,87.9,433.45,51.54c-86.8-39.86-185.23-25.9-267.79,19.4-49.44,27.13-90.58,67-85.17,127.58C85.62,256.14,139,281.6,185.27,305.46,198.22,311.57,160.23,292.54,185.27,305.46Z"
					fill="#20133a"
					opacity="0.6"
				/>
				<path
					ref={this.cardOut1}
					d="M114.37,93.53v45.3l105.7,33v-45.3Zm27,36.27a1.47,1.47,0,0,1-2,1.44l-18-5.3a2.94,2.94,0,0,1-2-2.64v-18a1.47,1.47,0,0,1,2-1.44l18,5.3a3,3,0,0,1,2,2.63Zm42.54,16a2.37,2.37,0,0,1-2.26,1.67,2.34,2.34,0,0,1-.71-.11l-32.44-10.12a2.37,2.37,0,1,1,1.41-4.53l32.44,10.12A2.37,2.37,0,0,1,183.87,145.82Zm29.87-.4a2.37,2.37,0,0,1-2.26,1.67,2.34,2.34,0,0,1-.71-.11l-62.31-19.44a2.37,2.37,0,1,1,1.41-4.53l62.31,19.44A2.37,2.37,0,0,1,213.74,145.41Zm0-9.72a2.37,2.37,0,0,1-3,1.56l-62.31-19.44a2.37,2.37,0,1,1,1.41-4.53l62.31,19.44A2.37,2.37,0,0,1,213.74,135.69Z"
					fill="#4eeca5"
				/>
				<path
					ref={this.cardOut2}
					d="M502.95,144.46v46.85l109.3,34.1V178.57Zm26.11,37a1.52,1.52,0,0,1-2.11,1.49l-18.64-5.48a3,3,0,0,1-2.11-2.73V156.13a1.52,1.52,0,0,1,2.11-1.49l18.64,5.48a3,3,0,0,1,2.11,2.73Zm45.77,17a2.45,2.45,0,0,1-2.34,1.72,2.41,2.41,0,0,1-.73-.11L538.2,189.67a2.45,2.45,0,0,1,1.46-4.69l33.55,10.47A2.46,2.46,0,0,1,574.82,198.53Zm30.88-.42a2.45,2.45,0,0,1-3.07,1.61L538.2,179.62a2.45,2.45,0,0,1,1.46-4.69L604.09,195A2.45,2.45,0,0,1,605.7,198.11Zm0-10.06a2.45,2.45,0,0,1-3.07,1.61L538.2,169.56a2.45,2.45,0,1,1,1.46-4.68L604.09,185A2.45,2.45,0,0,1,605.7,188.06Z"
					fill="#4eeca5"
				/>
				<path
					ref={this.cardOut3}
					d="M532.11,287.88V332.3l103.63,32.34V320.22Zm26.44,35.56a1.44,1.44,0,0,1-2,1.41l-17.68-5.2a2.89,2.89,0,0,1-2-2.58V299.4a1.44,1.44,0,0,1,2-1.41l17.68,5.2a2.89,2.89,0,0,1,2,2.58Zm41.71,15.7a2.33,2.33,0,0,1-2.22,1.63,2.28,2.28,0,0,1-.69-.11l-31.81-9.93a2.33,2.33,0,0,1,1.39-4.44l31.81,9.93A2.33,2.33,0,0,1,600.26,339.15Zm17.67-4a2.33,2.33,0,0,1-2.22,1.63,2.3,2.3,0,0,1-.69-.11l-49.49-15.43a2.33,2.33,0,0,1,1.39-4.44l49.49,15.43A2.33,2.33,0,0,1,617.94,335.12Zm11.61-5.9a2.32,2.32,0,0,1-2.91,1.53l-61.1-19.06a2.33,2.33,0,0,1,1.39-4.44L628,326.31A2.32,2.32,0,0,1,629.55,329.22Z"
					fill="#4eeca5"
				/>
				<path
					ref={this.cardOut4}
					d="M68.31,24.78v41.4l96.6,30.14V54.93ZM93,57.93a1.34,1.34,0,0,1-1.86,1.31L74.61,54.4A2.69,2.69,0,0,1,72.75,52V35.52a1.35,1.35,0,0,1,1.86-1.32L91.09,39A2.69,2.69,0,0,1,93,41.46Zm38.88,14.64a2.17,2.17,0,0,1-2.07,1.52,2.15,2.15,0,0,1-.65-.1L99.46,64.74a2.17,2.17,0,1,1,1.29-4.14l29.65,9.25A2.17,2.17,0,0,1,131.83,72.57Zm27.29-.37a2.17,2.17,0,0,1-2.07,1.52,2.13,2.13,0,0,1-.65-.1L99.46,55.85a2.17,2.17,0,1,1,1.29-4.14L157.7,69.48A2.17,2.17,0,0,1,159.13,72.2Zm0-8.89a2.17,2.17,0,0,1-2.72,1.43L99.46,47a2.17,2.17,0,0,1,1.29-4.14L157.7,60.6A2.17,2.17,0,0,1,159.13,63.31Z"
					fill="#4eeca5"
				/>
				<g className="frame">
					<polygon
						ref={this.frameBack}
						className="frame-back"
						points="520.19 369.69 186.53 265.67 186.53 25.93 520.19 129.96 520.19 369.69"
						fill="#391d71"
					/>
					<polygon
						ref={this.frameFront}
						className="frame-front"
						points="508.47 379.23 174.8 275.21 174.8 35.47 508.47 139.5 508.47 379.23"
						fill="#4eeca5"
					/>
					<g className="search">
						<polygon
							className="bar-back"
							ref={this.searchBarBack}
							points="182.98 45.59 182.98 70.43 500.29 169.36 500.29 144.52 182.98 45.59"
							fill="#391d71"
						/>
						<path
							className="bar-front"
							d="M427.93,135.21c0,3.16-2.56,4.94-5.73,4L200.08,70.77a8.43,8.43,0,0,1-5.73-7.49V61c0-3.16,2.56-4.94,5.73-4L422.2,125.43a8.43,8.43,0,0,1,5.73,7.49Z"
							fill="#dfe5eb"
						/>
						<g className="search-icon">
							<path
								d="M446.58,136.73c1.5,2,1.5,4.44,0,5.44s-3.94.19-5.44-1.82-1.5-4.44,0-5.44S445.08,134.73,446.58,136.73Z"
								fill="none"
								stroke="#dfe5eb"
								strokeMiterlimit="10"
								strokeWidth="2.18"
							/>
							<path
								d="M449.68,147.07a.77.77,0,0,1-.61-.31l-3.1-4.14a.77.77,0,0,1,1.23-.92l3.1,4.14a.77.77,0,0,1-.61,1.23Z"
								fill="#dfe5eb"
							/>
						</g>
						<g className="dot-icon">
							<path
								d="M494.16,155.2c0,2.2-1.79,3.42-4,2.71a6,6,0,0,1-4-5.27c0-2.2,1.79-3.42,4-2.71A6,6,0,0,1,494.16,155.2Z"
								fill="#dfe5eb"
							/>
							<path
								d="M481.73,151.22c0,2.2-1.78,3.42-4,2.71a6,6,0,0,1-4-5.27c0-2.2,1.79-3.42,4-2.71A6,6,0,0,1,481.73,151.22Z"
								fill="#dfe5eb"
							/>
							<path
								d="M469.31,147.24c0,2.2-1.78,3.42-4,2.71a6,6,0,0,1-4-5.27c0-2.2,1.79-3.42,4-2.71A6,6,0,0,1,469.31,147.24Z"
								fill="#dfe5eb"
							/>
						</g>
					</g>
				</g>
				<polygon
					ref={this.cardPlain}
					points="425.91 267.69 316.61 233.59 316.61 186.74 425.91 220.85 425.91 267.69"
					fill="#391d71"
				/>
				<polygon
					ref={this.cardDash1}
					points="425.91 326.26 316.61 292.15 316.61 245.31 425.91 279.41 425.91 326.26"
					fill="none"
					stroke="#391d71"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2.18"
					strokeDasharray="6.54 10.91"
				/>
				<polygon
					ref={this.cardDash2}
					points="304.72 170.85 195.42 136.74 195.42 89.9 304.72 124 304.72 170.85"
					fill="none"
					stroke="#391d71"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2.18"
					strokeDasharray="6.54 10.91"
				/>
				<path
					className="person-finger-1"
					d="M379.67,201.25a1.23,1.23,0,0,0,1.94-1.24,32,32,0,0,1-.63-4.42,13.33,13.33,0,0,1,.53-3.51,1.23,1.23,0,1,0-2.35-.7,15.91,15.91,0,0,0-.63,4.29,34.14,34.14,0,0,0,.68,4.86A1.22,1.22,0,0,0,379.67,201.25Z"
					fill="#f8a786"
				/>
				<path
					className="person-finger-2"
					d="M354.55,193.07a1.23,1.23,0,0,1-.67-2.2,32.28,32.28,0,0,0,3.35-2.95,13.34,13.34,0,0,0,1.88-3,1.23,1.23,0,0,1,2.25,1,16,16,0,0,1-2.31,3.67,34.25,34.25,0,0,1-3.68,3.25A1.22,1.22,0,0,1,354.55,193.07Z"
					fill="#f8a786"
				/>
				<g className="card-1" data-name="card">
					<polygon
						ref={this.cardIn1Base1}
						points="432.05 204.18 322.75 170.07 322.75 123.22 432.05 157.33 432.05 204.18"
						fill="#391d71"
					/>
					<polygon
						ref={this.cardIn1Base2}
						points="425.91 209.13 316.61 175.02 316.61 128.18 425.91 162.28 425.91 209.13"
						fill="#341448"
					/>
					<g ref={this.cardIn1Txt}>
						<path
							d="M415.79,173.08a2.36,2.36,0,0,1-.7-.11L353.34,153.7a2.35,2.35,0,1,1,1.4-4.49l61.75,19.27a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#dfe5eb"
						/>
						<path
							d="M415.79,182.71a2.35,2.35,0,0,1-.7-.11l-61.75-19.27a2.35,2.35,0,1,1,1.4-4.49l61.75,19.27a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#dfe5eb"
						/>
						<path
							d="M386.19,183.11a2.34,2.34,0,0,1-.7-.11l-32.15-10a2.35,2.35,0,1,1,1.4-4.49l32.15,10a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#dfe5eb"
						/>
					</g>
					<path
						ref={this.cardIn1Sq1}
						d="M343.76,166.5a1.46,1.46,0,0,1-2,1.43l-17.87-5.25a2.92,2.92,0,0,1-2-2.61V142.2a1.46,1.46,0,0,1,2-1.42L341.74,146a2.92,2.92,0,0,1,2,2.61Z"
						fill="#391d71"
					/>
					<path
						ref={this.cardIn1Sq2}
						d="M335.09,172.2a1.46,1.46,0,0,1-2,1.43l-17.87-5.25a2.92,2.92,0,0,1-2-2.61V147.89a1.46,1.46,0,0,1,2-1.43l17.87,5.25a2.92,2.92,0,0,1,2,2.61Z"
						fill="#dfe5eb"
					/>
				</g>
				<g className="card-2" data-name="card">
					<polygon
						ref={this.cardIn2Base1}
						points="304.72 286.88 195.42 252.78 195.42 205.93 304.72 240.04 304.72 286.88"
						fill="#391d71"
					/>
					<polygon
						ref={this.cardIn2Base2}
						points="279.1 288.88 169.8 254.78 169.8 207.93 279.1 242.04 279.1 288.88"
						fill="#341448"
					/>
					<g>
						<g ref={this.cardIn2Txt}>
							<path
								d="M269.62,251.72a2.38,2.38,0,0,1-.7-.11l-61.75-19.27a2.35,2.35,0,1,1,1.4-4.49l61.75,19.27a2.35,2.35,0,0,1-.7,4.6Z"
								fill="#4eeca5"
							/>
							<path
								d="M269.62,261.36a2.34,2.34,0,0,1-.7-.11L207.17,242a2.35,2.35,0,1,1,1.4-4.49l61.75,19.27a2.35,2.35,0,0,1-.7,4.6Z"
								fill="#4eeca5"
							/>
							<path
								d="M240,261.76a2.3,2.3,0,0,1-.7-.11l-32.15-10a2.35,2.35,0,1,1,1.4-4.49l32.15,10a2.35,2.35,0,0,1-.7,4.6Z"
								fill="#4eeca5"
							/>
						</g>
						<path
							ref={this.cardIn2Sq1}
							d="M201.16,245.86a1.46,1.46,0,0,1-2,1.43L181.28,242a2.92,2.92,0,0,1-2-2.61V221.56a1.46,1.46,0,0,1,2-1.43l17.87,5.25a2.92,2.92,0,0,1,2,2.61Z"
							fill="#391d71"
						/>
						<path
							ref={this.cardIn2Sq2}
							d="M197.81,248a1.46,1.46,0,0,1-2,1.42l-17.87-5.25a2.92,2.92,0,0,1-2-2.61V223.72a1.46,1.46,0,0,1,2-1.43l17.87,5.25a2.92,2.92,0,0,1,2,2.61Z"
							fill="#dfe5eb"
						/>
					</g>
				</g>
				<g className="card-3" data-name="card">
					<polygon
						ref={this.cardIn3Base1}
						points="304.72 230.83 195.42 196.73 195.42 149.88 304.72 183.99 304.72 230.83"
						fill="#341448"
					/>
					<g ref={this.cardIn3Txt}>
						<path
							d="M256.2,184.39a2.37,2.37,0,0,1-.7-.11l-22.91-7.23a2.35,2.35,0,1,1,1.4-4.49l22.91,7.23a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#4eeca5"
						/>
						<path
							d="M295,206.07a2.33,2.33,0,0,1-.7-.11l-61.75-19.27a2.35,2.35,0,1,1,1.4-4.49l61.75,19.27a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#4eeca5"
						/>
						<path
							d="M265.44,206.47a2.34,2.34,0,0,1-.7-.11l-32.15-10a2.35,2.35,0,1,1,1.4-4.49l32.15,10a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#4eeca5"
						/>
					</g>
					<path
						ref={this.cardIn3Sq1}
						d="M226.58,190.57a1.46,1.46,0,0,1-2,1.42l-17.87-5.25a2.92,2.92,0,0,1-2-2.61V166.27a1.46,1.46,0,0,1,2-1.43l17.87,5.25a2.92,2.92,0,0,1,2,2.61Z"
						fill="#391d71"
					/>
					<path
						ref={this.cardIn3Sq2}
						d="M223.22,192.74a1.46,1.46,0,0,1-2,1.42l-17.87-5.25a2.92,2.92,0,0,1-2-2.61V168.43a1.46,1.46,0,0,1,2-1.43l17.87,5.25a2.92,2.92,0,0,1,2,2.61Z"
						fill="#dfe5eb"
					/>
					<path
						ref={this.cardIn3Dot}
						d="M299,181.88a5.09,5.09,0,0,1-6.42,5.12,8.26,8.26,0,0,1-6.42-7.72,5.09,5.09,0,0,1,6.42-5.12A8.26,8.26,0,0,1,299,181.88Z"
						fill="#fd476c"
					/>
				</g>
				<g className="card-4" data-name="card">
					<polygon
						ref={this.cardIn4Base1}
						points="559.81 272.05 450.51 237.94 450.51 191.09 559.81 225.2 559.81 272.05"
						fill="#1a217c"
					/>
					<polygon
						ref={this.cardIn4Base2}
						points="553.68 276.83 444.38 242.72 444.38 195.87 553.68 229.98 553.68 276.83"
						fill="#fd476c"
					/>
					<g ref={this.cardIn4Txt}>
						<path
							d="M543.38,240.11a2.36,2.36,0,0,1-.7-.11l-61.75-19.27a2.35,2.35,0,1,1,1.4-4.49l61.75,19.27a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#dfe5eb"
						/>
						<path
							d="M532.82,246.4a2.34,2.34,0,0,1-.7-.11l-51.19-15.93a2.35,2.35,0,1,1,1.4-4.49l51.19,15.93a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#dfe5eb"
						/>
						<path
							d="M513.78,250.14a2.3,2.3,0,0,1-.7-.11l-32.15-10a2.35,2.35,0,1,1,1.4-4.49l32.15,10a2.35,2.35,0,0,1-.7,4.6Z"
							fill="#dfe5eb"
						/>
					</g>
					<path
						ref={this.cardIn4Sq1}
						d="M471.34,233.53a1.46,1.46,0,0,1-2,1.43l-17.87-5.25a2.92,2.92,0,0,1-2-2.61V209.22a1.46,1.46,0,0,1,2-1.43L469.33,213a2.92,2.92,0,0,1,2,2.61Z"
						fill="#1a217c"
					/>
					<path
						ref={this.cardIn4Sq2}
						d="M462.67,239.22a1.46,1.46,0,0,1-2,1.43l-17.87-5.25a2.92,2.92,0,0,1-2-2.61V214.92a1.46,1.46,0,0,1,2-1.43l17.87,5.25a2.92,2.92,0,0,1,2,2.61Z"
						fill="#dfe5eb"
					/>
				</g>
				<g className="person">
					<ellipse
						ref={this.personGround}
						cx="328.05"
						cy="416.68"
						rx="38.47"
						ry="78.94"
						transform="translate(-99.19 730.85) rotate(-88.12)"
						fill="#4eeca5"
					/>
					<path
						d="M298.05,422.76s-2.1,8.18,2,10.21c4.62,2.31,11.6.28,19.61-3.52,6.83-3.24,5.33-7.39.18-8.38s-12.51.73-12.51.73Z"
						fill="#20133a"
					/>
					<path
						d="M332,426.4s-3,7.84.71,10.38c4.27,2.88,11.66,2.14,19.89-1.14,6.78-2.7,6.18-6.7,1.19-8.29s-12.51-.78-12.51-.78Z"
						fill="#20133a"
					/>
					<path
						d="M333.81,307.95c3.65,13.23,12,49.27,12.77,54.66a27.5,27.5,0,0,1,.2,4.12c0,2.85-.16,5.7-.28,8.54-.59,15-1.39,36.49-2.15,51.44-.06,1.21-.18,2.41-.18,3.62,0,3.24-13.46,6.12-13.46,2.26,0-13.34-1-36.25-1.53-49.58-.24-5.74-.46-11.47-.89-17.2,0,.12,0,.24,0,.37a12.05,12.05,0,0,0-.52-1.92c-.31-1.12-.66-2.24-1-3.35-1.25-4-11.61-27.35-14.36-34.71-1.14-3,0-5.36-1.11-8.4a26,26,0,0,1-1.93-6.2,12.45,12.45,0,0,1,8.49-13.23C324.16,296.46,332,301.46,333.81,307.95Z"
						fill="#391d71"
					/>
					<path
						d="M320.62,309.82c-.43,11.19-3.52,55.21-3.65,56.7-.35,3.88-.78,7.76-1.22,11.64q-1.29,11.47-2.66,22.94c-.69,5.92-1.39,18.42-2.13,24.34-.6,4.78-14,5.86-13.79,2.54.42-5.93.62-22,.79-28,.19-6.7.39-13.41.55-20.11.08-3.48.17-7,.22-10.45,0-2.18.11-4.36,0-6.54,0,.11,0,.22,0,.33,0-1.35-4.86-38.32-3.62-52.72.42-4.85,2.23-9.5,6.73-11.63a12.16,12.16,0,0,1,11.77.82C317,302,320.81,305.74,320.62,309.82Z"
						fill="#391d71"
					/>
					<path
						d="M360.46,183.34a1.23,1.23,0,0,0-.4-1.69l-.05,0a1.23,1.23,0,0,0-2-1.36l-.13.2a1.26,1.26,0,0,0-.3-.26,1.23,1.23,0,0,0-1.69.42s-.3.51-.67,1.17a1.23,1.23,0,0,0-1.58.61,24.58,24.58,0,0,0-1.27,3.3,22.62,22.62,0,0,0-.36,2.74l-2.78,8.26a3.25,3.25,0,1,0,6.15,2.07l2.78-8.26a3.15,3.15,0,0,0,.1-1.66c.46-.12,1.22-.37.68-.58-.73-.28-.31-1.48-.3-1.52A38.3,38.3,0,0,1,360.46,183.34Z"
						fill="#f8a786"
					/>
					<path
						d="M381.51,190a1.23,1.23,0,0,1,1.4-1H383a1.23,1.23,0,0,1,2.44.3s0,.11,0,.24a1.25,1.25,0,0,1,.39,0,1.23,1.23,0,0,1,1,1.42c0,.05-.1.59-.25,1.32a1.22,1.22,0,0,1,.8,1.49,24.5,24.5,0,0,1-1.18,3.33,22.37,22.37,0,0,1-1.51,2.32l-3.26,8.08a3.25,3.25,0,1,1-6-2.43l3.26-8.08a3.16,3.16,0,0,1,1-1.32c-.27-.39-.69-1.07-.14-.88.73.26,1.2-.92,1.21-1A38,38,0,0,0,381.51,190Z"
						fill="#f8a786"
					/>
					<path
						d="M349.24,193.48a106.94,106.94,0,0,1-3.72,10.25c-1.54,3.85-9.72,20.36-12.27,23.39-2.33,2.76-14.19,9.18-21,15.93-13.44,13.32-16.11,45-18.14,68.64-1.23,14.31,46.68,19.63,43.65,6-.5-2.25-3.79-27.88-1.34-62.11.23-3.28-5.77-6.41-8.37-8.48,5.82-4.7,11.48-9.85,14.14-13.94,4.28-6.57,11-23,13.42-31,.71-2.36,1.37-4.73,1.94-7.12S350,190.74,349.24,193.48Z"
						fill="#dfe5eb"
					/>
					<path
						d="M336.41,255.59c.2-2.77-1,0-1.48,3.23-4.84,34-3.35,59.77-3,62a4.52,4.52,0,0,1-1.19,4c4.81-1.08,7.86-3.4,7-7.15C337.25,315.45,334,289.82,336.41,255.59Z"
						fill="#fff"
					/>
					<path
						d="M315.2,243.57a69.84,69.84,0,0,1,.28-17.78,4.36,4.36,0,0,1,8.59,1.46A78.15,78.15,0,0,0,324,243.6C324.35,246.6,315.52,246.36,315.2,243.57Z"
						fill="#f8a786"
					/>
					<ellipse
						cx="325.58"
						cy="221.72"
						rx="15.25"
						ry="10.83"
						transform="translate(99.53 544.31) rotate(-89.23)"
						fill="#f8a786"
					/>
					<path
						d="M307.56,220.51c-.77,10.56,6.78,13.86,7.2,18.32.39,4.24,9.86-1,7.75-7.07-1.51-4.34,2.12-6.53,2.54-3.38.36,2.74,3.3,2.33,3.3-1.26,0-4.66,11.25-5,9.26-18.87-1.79-12.42-13.44-11.35-16.92-6.39C317.58,206.29,308.33,210,307.56,220.51Z"
						fill="#20133a"
					/>
					<path
						d="M376,201.26a108.43,108.43,0,0,1-4.24,10c-1.74,3.77-10.76,19.92-13.46,22.88-3.54,3.86-24.16,14.27-30.21,17.36a7.14,7.14,0,0,0-4,6,6.53,6.53,0,0,0,3.4,6.08c2.08,1.11,4.31,3.88,6.39,2.63,2.23-1.34,26.19-16.49,33-26,4.61-6.41,12.21-22.51,15-30.31.82-2.31,1.61-4.64,2.31-7S376.94,198.57,376,201.26Z"
						fill="#dfe5eb"
					/>
				</g>
			</svg>
		);
	}
}

export default HomePageIllustration;
